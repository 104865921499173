import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg">
          <div className="bp">
            <div className="wrapper bp bp2">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="current">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>Buďte krásná</h2>
                <h3>
                  Hydratovaná pokožka, dobře živená zevnitř, je vizitkou každé
                  upravené ženy. Když jsme přemýšleli o ženách jako jste vy,
                  připravili jsme návod <br />
                  na péči, ve kterém najdete tipy na každodenní péči o pokožku.
                  Objevte svou přirozenou krásu a naučte se poskytovat své
                  pokožce každý den účinnou péči!
                  <br />
                  <br />
                  <span className="bold">
                    Zvolte téma a dozvíte se více:
                    <br />
                  </span>
                </h3>
                <p>&nbsp;</p>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  Buďte krásná
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="content boxcontent">
              <div className="row">
                <a href="/budte-krasna/sucha-pokozka/" className="bpbox left">
                  <img src="/assets/Uploads/bp2.jpg" alt="bp2" />
                  <span className="title">Suchá pokožka</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    <span>
                      Hydratovaná a dobře vyživená pokožka je vizitkou každé
                      ženy.
                    </span>
                  </p>
                  <span className="button">Více</span>
                </a>
                <a href="/budte-krasna/pece-o-plet/" className="bpbox right">
                  <img src="/assets/Uploads/bp3.jpg" alt="bp3" />
                  <span className="title">Péče o pleť</span>
                  <p>
                    Chcete mít pěknou a zdravou pleť? Zjistěte, čím je to
                    ovlivněno.
                  </p>
                  <span className="button">Více</span>
                </a>
              </div>
              <div className="row">
                <a href="/budte-krasna/pece-o-rty/" className="bpbox left">
                  <img src="/assets/Uploads/bp4.jpg" alt="bp4" />
                  <span className="title">Péče o rty</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    <span>
                      Rty jsou nejčastěji opomíjenou částí našeho těla. Co je
                      potřeba o nich vědet?
                    </span>
                  </p>
                  <span className="button">Více</span>
                </a>
                <a href="/budte-krasna/pece-o-ruce/" className="bpbox right">
                  <img src="/assets/Uploads/bp5.jpg" alt="bp5" />
                  <span className="title">Péče o ruce</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    <span>
                      Jak účinnně pečovat o své ruce a udržet co nejdéle jejich
                      mladý vzhled?
                    </span>
                  </p>
                  <span className="button">Více</span>
                </a>
              </div>
              <div className="row">
                <a href="/budte-krasna/pece-o-nohy/" className="bpbox left">
                  <img src="/assets/Uploads/bp6.jpg" alt="bp6" />
                  <span className="title">Péče o nohy</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    Pečující rituál, který vám umožní užít si krásné nohy po
                    celý rok.
                  </p>
                  <span className="button">Více</span>
                </a>
                <a href="/budte-krasna/pece-o-tlo/" className="bpbox right">
                  <img src="/assets/Uploads/bp7.jpg" alt="bp7" />
                  <span className="title">Péče o tělo</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    Zdravá a krásná pokožka vyžaduje promyšlenou péči každý den.
                  </p>
                  <span className="button">Více</span>
                </a>
              </div>
              <div id="fb_68"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
